<template>
  <div>
    <div class="row" v-show="false">
      <div class="col-md-12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            <b>Data tables</b> The <code>v-data-table</code> component is used
            for displaying tabular data. Features include sorting, searching,
            pagination, inline-editing, header tooltips, and row selection.
            <a
              class="font-weight-bold"
              href="https://vuetifyjs.com/en/components/data-tables"
              target="_blank"
            >
              See documentation.
            </a>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-card :loading="eventSaveLoading">
          <template slot="progress">
            <v-overlay absolute class="d-flex flex-column text-center">
              <div>
                <v-progress-circular
                  size="150"
                  color="#1BC5BD "
                  :value="eventSaveLoading"
                  indeterminate
                  ref="savingLoader"
                >
                  <span>{{
                    $t("PAGES.COMPANIES.FORM.MANAGE.SAVING_LOADING")
                  }}</span>
                </v-progress-circular>
              </div>
            </v-overlay>
          </template>
          <v-card-title>
            {{ $t("PAGES.EVENTS.NEW.TITLE") }}
          </v-card-title>
          <b-card-body>
            <v-card-text>
              <v-form ref="edit_event_form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        :value="singleItem.id"
                        disabled
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.ID')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        :value="singleItem.code"
                        disabled
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.CODE')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8" sm="6">
                      <v-text-field
                        v-model="singleItem.title"
                        :rules="requiredRule"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.TITLE')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.DESCRIPTION')"
                        v-model="singleItem.description"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2">
                      <DateBox
                        :initial-date="event_date_time_from_formatted"
                        :final-date.sync="singleItem.start_date"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.START_DATE')"
                        :min="today()"
                      ></DateBox>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        type="time"
                        v-model="startHour"
                        :rules="numberRule"
                        @keypress="isNumber($event)"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <DateBox
                        :initial-date.sync="event_date_time_to_formatted"
                        :final-date.sync="singleItem.end_date"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.END_DATE')"
                        :min="minEndDate"
                        :max="maxEndDate"
                      ></DateBox>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        type="time"
                        v-model="endHour"
                        :rules="numberRule"
                        @keypress="isNumber($event)"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        v-model="singleItem.time_slot"
                        :rules="numberRule"
                        @keypress="isNumber($event)"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.TIME_SLOT')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <v-text-field
                        v-model="singleItem.reservations_per_time_slot"
                        :rules="numberRule"
                        @keypress="isNumber($event)"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="
                          $t(
                            'PAGES.EVENTS.ATTRIBUTES.RESERVATIONS_PER_TIME_SLOT'
                          )
                        "
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        v-model="singleItem.owner"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.OWNER')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="cityId"
                        :items="cities_found"
                        :loading="isLoadingCitiesSearch"
                        :search-input.sync="cities_search"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :filter="customFilter"
                        item-text="denomination"
                        item-value="id"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.CITY')"
                        clearable
                      >
                        <template slot="selection" slot-scope="{ item, tile }">
                          {{ item.denomination }} ({{
                            item.state.abbreviation
                          }}) {{ tile }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.denomination }} ({{
                            data.item.state.abbreviation
                          }})
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="singleItem.address"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.ADDRESS')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.NOTES')"
                        v-model="singleItem.notes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="selectedDoses"
                        :items="doses_found"
                        :label="$t('PAGES.EVENTS.ATTRIBUTES.DOSES')"
                        multiple
                        filled
                        dense
                        item-text="title"
                        return-object
                        chips
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggleDoses">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  selectedDoses.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                                >{{ iconSelectDoses }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ $t("COMMON.SELECT_ALL_FEMALE") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-content v-if="likesAllDoses">
                              <v-list-item-title
                                >{{ $t("PAGES.EVENTS.EDIT.ALL_SELECTED") }}
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content v-else-if="likesSomeDoses">
                              <v-list-item-title
                                >{{ $t("PAGES.EVENTS.EDIT.DOSES_COUNT") }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                >{{ selectedDoses.length }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-content v-else>
                              <v-list-item-title>
                                How could you not like fruit?
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Go ahead, make a selection above!
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-btn color="success" class="mr-4" @click="validate">
                        {{ $t("COMMON.BUTTON.SAVE") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <div style="flex: 1 1 auto"></div>
          </b-card-body>
        </v-card>
      </div>
    </div>
    <DialogMessage :vars="dialogVars" :dialog.sync="showDialog"></DialogMessage>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import rules from "@/core/helper/rules.helper.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import DateBox from "@/view/commons/forms/DateBox";

import { CREATE_EVENT_DATA } from "@/core/services/store/events.module";
import DialogMessage from "@/view/commons/DialogMessage";
import moment from "moment";
import { FETCH_CITIES_LIST } from "@/core/services/store/cities.module";
import { FETCH_DOSES_LIST } from "@/core/services/store/doses.module";

export default {
  name: "EditEventsForm",
  data() {
    return {
      startHour: {},
      endHour: {},
      singleItem: {},
      cityId: Number,
      selectedDoses: [],
      isLoadingCitiesSearch: false,
      cities_search: {},
      showDialog: false,
      dialogVars: {
        area: {},
        message: {
          title: String,
          text: String
        },
        type: String,
        route: false
      },
      valid: true,
      clean_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      city_init_filter: {
        query: [],
        filters: {
          id: {
            _eq: ""
          }
        },
        sort: {},
        page: 1
      },
      cities_filters: {
        query: [],
        filters: {
          _cmb_or: "_denomination,_city_cad,_city_state_den",
          _denomination: {
            denomination: {
              _like: "%"
            }
          },
          _city_cad: {
            cadastre_code: {
              _like: "%"
            }
          },
          _city_state_den: {
            "state.denomination": {
              _like: "%"
            }
          }
        },
        sort: {},
        limit: 100,
        page: 1
      },
      requiredRule: [
        (v) => {
          return rules.requiredRules(v);
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "eventLoading",
      "getEventApiErrors",
      "eventInUse",
      "getEventSavingSuccess",
      "eventSaveLoading",
      "citiesList",
      "dosesList"
    ]),
    iconSelectDoses() {
      if (this.likesAllDoses) return "mdi-close-box";
      if (this.likesSomeDoses) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllDoses() {
      return this.selectedDoses.length === this.doses_found.length;
    },
    likesSomeDoses() {
      return this.selectedDoses.length > 0 && !this.likesAllDoses;
    },
    doses_found() {
      return this.dosesList.data != undefined ? this.dosesList.data : [];
    },
    cities_found() {
      return Object.assign([], this.citiesList);
    },
    isExpired() {
      var now = moment(new Date());
      var startDate = moment(this.singleItem.start_date);
      var endDate = moment(this.singleItem.end_date);
      return startDate.isBefore(now) && endDate.isBefore(now) ? true : false;
    },
    event_date_time_from_formatted() {
      return this.singleItem.start_date
        ? moment(String(this.singleItem.start_date)).format("YYYY-MM-DD")
        : "";
    },
    event_date_time_to_formatted() {
      return this.singleItem.end_date
        ? moment(String(this.singleItem.end_date)).format("YYYY-MM-DD")
        : "";
    },
    maxEndDate() {
      return this.singleItem.start_date != undefined
        ? moment(new Date(this.singleItem.start_date))
            .add(30, "days")
            .format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD");
    },
    minEndDate() {
      return this.singleItem.start_date != undefined
        ? moment(new Date(this.singleItem.start_date)).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD");
    }
  },
  components: {
    DialogMessage,
    DateBox
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.EVENTS.AREA") },
      { title: this.$t("PAGES.EVENTS.NEW.TITLE") }
    ]);
    this.$store.dispatch(FETCH_DOSES_LIST, this.clean_filters);
  },
  methods: {
    setDateTime(dateStr, timeStr) {
      let date = moment(dateStr),
        time = moment(timeStr, "HH:mm");
      return date.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second")
      });
    },
    toggleDoses() {
      this.$nextTick(() => {
        if (this.likesAllDoses) {
          this.selectedDoses = [];
        } else {
          this.selectedDoses = this.doses_found.slice();
        }
      });
    },
    customFilter: function (item, queryText) {
      var denomination = item.denomination.toLowerCase();
      var cadastre_code = item.cadastre_code.toLowerCase();
      var state = item.state.denomination.toLowerCase();
      var searchText = queryText.toLowerCase();

      return (
        denomination.indexOf(searchText) > -1 ||
        cadastre_code.indexOf(searchText) > -1 ||
        state.indexOf(searchText) > -1
      );
    },
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    validate() {
      if (this.$refs.edit_event_form.validate()) {
        this.save();
      }
    },
    save() {
      var eventToUpdate = Object.assign({}, this.singleItem);
      this.$delete(eventToUpdate, "updatedAt");
      this.$delete(eventToUpdate, "createdAt");
      this.$delete(eventToUpdate, "city");
      this.$delete(eventToUpdate, "doses");
      this.$delete(eventToUpdate, "reservations");
      this.$delete(eventToUpdate, "id");
      this.$delete(eventToUpdate, "code");
      let listDoses = [];
      this.selectedDoses.forEach(function (elem) {
        listDoses.push(elem.id);
      });
      eventToUpdate.doses = listDoses;
      eventToUpdate.city = this.cityId;
      eventToUpdate.status = true;
      eventToUpdate.start_date = this.setDateTime(
        eventToUpdate.start_date,
        this.startHour
      );
      eventToUpdate.end_date = this.setDateTime(
        eventToUpdate.end_date,
        this.endHour
      );
      this.$store.dispatch(CREATE_EVENT_DATA, eventToUpdate);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  watch: {
    cities_found() {
      this.isLoadingCitiesSearch = false;
    },
    cities_search(val) {
      // if (this.citiesList.length > 0) return;
      if (val == null) return;
      // Items have already been requested
      if (this.isLoadingCitiesSearch) return;
      this.isLoadingCitiesSearch = true;
      this.cities_filters.filters._denomination.denomination._like =
        "%" + val + "%";
      this.cities_filters.filters._city_cad.cadastre_code._like =
        "%" + val + "%";
      this.cities_filters.filters._city_state_den["state.denomination"]._like =
        "%" + val + "%";
      this.cities_filters.limit = 100;
      this.$store.dispatch(FETCH_CITIES_LIST, this.cities_filters);
    },
    getEventApiErrors: function () {
      this.dialogVars.message.title = this.$t(
        "PAGES.EVENTS.COMMON.ERROR_ONSAVE"
      );
      this.dialogVars.message.text = this.getEventApiErrors;
      this.dialogVars.type = "error";
      this.showDialog = true;
    },
    getEventSavingSuccess: function () {
      this.dialogVars.message.title = this.$t("COMMON.SUCCESS.TITLE");
      this.dialogVars.message.text = this.getEventSavingSuccess;
      this.dialogVars.type = "success";
      this.dialogVars.route = "events_list";
      this.showDialog = true;
    }
  }
};
</script>
